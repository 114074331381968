import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { BasicLayout } from "./layouts/BasicLayout";
import { VadimTaplink } from "./components/VadimTaplink/VadimTaplink";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        {
          path: "nevazhno",
          element: <VadimTaplink />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
