import { Link } from "react-router-dom";
import "./VadimTaplink.css";
import photo from "../../assets/images/vadim.jpg";
import vk from "../../assets/icons/vk.png";
import telega from "../../assets/icons/telegram.png";
import insta from "../../assets/icons/insta.png";

export const VadimTaplink = () => {
  return (
    <section className="taplink">
      <div
        className="taplink-photo"
        style={{ backgroundImage: `url(${photo})` }}
      ></div>
      <div className="taplink-name">
        <h2>Вадим Неважно</h2>
      </div>
      <Link className="taplink-link" to="https://vk.com/nevazhno.dude">
        <div className="taplink-icon">
          <img src={vk} alt="" />
        </div>
        <p>Мой Вконтакте</p>
      </Link>
      <Link className="taplink-link" to="https://t.me/nevazhno_dude">
        <div className="taplink-icon">
          <img src={telega} alt="" />
        </div>
        <p>Мой Телеграм</p>
      </Link>
      <Link
        className="taplink-link"
        to="https://www.instagram.com/vadim__nevazhno?igsh=c2hjbHg0anN6azI4&utm_source=qr"
      >
        <div className="taplink-icon">
          <img src={insta} alt="" />
        </div>
        <p>Моя Инста</p>
      </Link>
    </section>
  );
};
